export default async ({ $axios, app }) => {
  const apiUrl = `${window.location.origin}/env.json` // Динамическое определение URL

  try {
    // Используем конкретный URL, игнорируя глобальный baseURL для этого запроса
    const response = await $axios.get(apiUrl, { baseURL: undefined })
    const envConfig = response.data

    // Обновляем конфиг с полученными данными
    app.$config = { ...app.$config, ...envConfig }
  } catch (error) {
    console.error('Ошибка загрузки данных из env.json: ', error)
  }
}

import { IBanner } from 'types/DTO/banners'
import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime'

export default class Banners {
  locationHome: string
  locationNews: string
  locationHomeUnderSearch: string

  constructor(private $config: NuxtRuntimeConfig) {
    this.locationHome = 'home'
    this.locationNews = 'news'
    this.locationHomeUnderSearch = 'home_under_search'
  }

  adapt(data: any): IBanner[] {
    const adapted: IBanner[] = []
    if (Array.isArray(data)) {
      data.forEach((item: any) => {
        return adapted.push(this.adaptItem(item.attributes))
      })
    } else {
      Object.assign(adapted, this.adaptItem(data.attributes))
    }
    return adapted
  }

  adaptItem(data: any): IBanner {
    return {
      id: data.id,
      type: data.type,
      images: {
        raw: this.$config.BASE_URL + data['image-mobile'],
        medium: this.$config.BASE_URL + data.image,
        thumbnail: '',
      },
      title: data.title,
      link: { external: false, link: data.link },
      is_active: data['is-active'],
      location: data.location,
    }
  }

  getBannersByLocation(data: IBanner[], location: string) {
    if (Array.isArray(data) && data.length > 0) {
      return data.filter((banner: IBanner) => banner.location === location)
    } else {
      return []
    }
  }

  bannerViewChecked(container: any) {
    const div_position = container.getBoundingClientRect()

    const div_top = div_position.top
    const div_left = div_position.left

    const div_width = container.offsetWidth
    const div_height = container.offsetHeight

    const top_scroll = document.documentElement.scrollTop || document.body.scrollTop
    const left_scroll = document.documentElement.scrollLeft || document.body.scrollLeft

    const screen_width = window.innerWidth
    const screen_height = window.innerHeight

    const see_x1 = left_scroll
    const see_x2 = screen_width + left_scroll
    const see_y1 = top_scroll
    const see_y2 = screen_height + top_scroll

    const div_x1 = div_left + left_scroll
    const div_x2 = div_left + div_width + left_scroll
    const div_y1 = div_top + top_scroll
    const div_y2 = div_top + div_height + top_scroll

    if (div_x1 >= see_x1 && div_x2 <= see_x2 && div_y1 >= see_y1 && div_y2 <= see_y2) {
      return true
    } else {
      return false
    }
  }
}

import Vue from 'vue'
import YmapPlugin from 'vue-yandex-maps'

export default ({ $config }) => {
  const settings = {
    apiKey: $config.YANDEX_KEY, // Используем значение из Nuxt config
    lang: 'ru_RU',
    version: '2.1',
  }

  Vue.use(YmapPlugin, settings)
}

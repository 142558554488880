import { IHttp, IHttpResponse } from 'services/common/Http'
import axios from 'axios'
import { getCookie } from '~/utils/getCookie'
import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime'

export interface IAnyQueryMethods {
  sendAnyQueryEvent(operation: string, data: any): any
}

export default class AnyQuery implements IAnyQueryMethods {
  constructor(
    http: IHttp,
    private $config: NuxtRuntimeConfig,
  ) {}

  async sendAnyQueryEvent(operation: string, data: any): Promise<any> {
    const prodServer = this.$config.BASE_DOMAIN_URL === 'https://aurma.kz'
    const sessionId = getCookie('sessionId')
    const userGUID = getCookie('userId')
    const viewGUID = localStorage.getItem('viewId')
    // @ts-ignore
    if (prodServer && sessionId && userGUID && viewGUID) {
      return await axios.post(`https://tracking-app.diginetica.net/event`, {
        eventType: operation,
        apiKey: this.$config.DIGINETICA_API_KEY,
        sessionId,
        userGUID,
        viewGUID,
        channel: window.innerWidth < 1000 ? 'WEB_MOBILE' : 'WEB',
        ...data,
      })
    }
  }
}

import { IUser } from 'types/DTO/users'
import { IHttp } from 'services/common/Http'

export interface IUsers {
  adaptUser(user: IUser): IUser
}

export default class Users implements IUsers {
  constructor(private $config: any) {}
  adaptUser(user: IUser): IUser {
    return {
      id: user.id,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
      avatar:
        user.avatar && !user.avatar.includes('null') ? this.$config.BASE_URL + user.avatar : '',
      player_id: user.player_id,
      auth_key: user.auth_key,
      city_id: user.city_id,
      balances: user.mind_box.balances,
    }
  }
}

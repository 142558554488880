import { IArticle, IArticleRaw } from '~/types/DTO/articles'
import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime'

export default class NewsAdapter {
  constructor(private $config: NuxtRuntimeConfig) {}

  adapt(data: IArticleRaw[] | IArticleRaw): IArticle[] {
    const adapted: IArticle[] = []

    if (Array.isArray(data)) {
      data.forEach((item: IArticleRaw) => {
        return adapted.push(this.adaptItem(item))
      })
    } else {
      Object.assign(adapted, this.adaptItem(data))
    }

    return adapted
  }

  adaptItem(item: IArticleRaw): IArticle {
    return {
      id: item.id,
      title: item.title,
      slug: item.slug,
      short_description: item.short_description,
      description: item.description,
      created_at: new Intl.DateTimeFormat('ru-RU').format(new Date(item.created_at)),
      category: {
        id: item.category_id,
        title: item.category,
      },
      image: {
        raw: this.$config.BASE_URL + item.imageoriginal,
        medium: this.$config.BASE_URL + item.imagepreview,
        thumbnail: this.$config.BASE_URL + item.imagethumbs,
      },
      products: item?.products ?? [],
    }
  }
}

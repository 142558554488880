export interface ICheckoutService {
  createCryptogram(elementId: string): string
}

/**
 * @class CheckoutService
 * @description A class that has methods of cryptogram create
 */
export default class CheckoutService implements ICheckoutService {
  constructor(private $config: any) {}

  /**
   * @name createCryptogram
   * @description returns cryptogram packet
   * @param {string} elementId
   */
  createCryptogram(elementId: string): string {
    // @todo - удалить старый метод
    // @ts-ignore
    const checkout = new cp.Checkout(
      this.$config.CP_PUBLIC_ID || 'pk_b7a420347eb6532b33948bdae1737',
      document.getElementById(elementId),
    )
    const result = checkout.createCryptogramPacket()
    return result.packet
  }

  createCryptogramNew(checkoutFields: object): string {
    // @ts-ignore
    const checkout = new cp.Checkout({
      publicId: this.$config.CP_PUBLIC_ID || 'pk_b7a420347eb6532b33948bdae1737',
    })
    const result = checkout.createCryptogramPacket(checkoutFields)
    return result.packet
  }

  async createCryptogramPersonal(checkoutFields: object): Promise<any> {
    // @ts-ignore
    const checkout = new cp.Checkout({
      publicId: this.$config.CP_PUBLIC_ID || 'pk_b7a420347eb6532b33948bdae1737',
    })
    const result = await checkout.createCryptogramPacket(checkoutFields)
    return result
  }
}
